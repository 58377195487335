@import url('https://fonts.googleapis.com/css2?family=Coming+Soon&family=Open+Sans:wght@300&display=swap');

.headerNew {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
  height: 110px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  font-family: 'Poppins' !important;
}

.gridLogoHeader {
  display: flex;
  align-items: center;
}

.menuHamburguesa {
  color: #262626 !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins' !important;
  text-decoration: none;
}

.headerTitles {
  font-size: 16px !important;
}

@media (min-width: 555px ) {
  .logoHeader {
    width: 230px;
    height: auto;
    margin-left: 20%;
  }
  .nav-area {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding-right: 5%;
  }
}

@media (max-width: 554.99px ) {
  .logoHeader {
    width: 160px;
    height: auto;
    margin-left: 20%;
  }
}

@media (max-width: 1264px ) {
  .nav-area {
    display: flex;
    align-items: center;
    padding-right: 20%;
  }
}

.logo {
  text-decoration: none;
  color: inherit;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  float: right;
}

.menu-items {
  position: relative;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-family: 'Poppins' !important;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #f2f2f2;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0.5;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  /* font-size: 0.875rem; */
  z-index: 2;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}
